<template>
  <div>
    <div class="content">
      <div class="contentL">
        <div class="tree">
          <div class="" v-for="(item, index) in dataList" :key="index">
            <div class="treeTitle" @click="titleTree(item)">
              <span class="searchIcon " :class="icon ? 'search-title' : ''" @click="searchIcon"></span>
              <img src="../../assets/images/dailyWork/iconTitle.png" alt="">
              <div> {{ item.label }}</div>
            </div>
            <div class="demo" v-if="icon">
              <div class="treeContent" v-for="(content, index) in item.children" :key="index"
                @click="treeContent(item, content)">
                <span class=" searchContent1"></span>
                <div class="treeContentText" :class="selectContent == content.id ? 'treeContentText1' : ''">
                  <div class="">
                    <img v-if="selectContent == content.id" src="../../assets/images/dailyWork/0.png" alt="">
                    <img v-else src="../../assets/images/dailyWork/1.png" alt="">
                  </div>
                  <div class="demo">{{ content.programName }}</div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="contentR">
        <div class="prompt">
          <img src="../../assets/images/LTicon.png" alt="">
          <div class="promptText">培训计划</div>
        </div>
        <div class="searchContent">
          <div class="search">
            <div class="formList">
              <el-form :inline="true" :model="formInline" class="demo-form-inline">

                <el-form-item label="计划开始时间：">
                  <el-date-picker v-model="formInline.startTime" value-format="yyyy-MM-dd" type="date"
                  :append-to-body="false"
                    placeholder="选择计划结束时间">
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="计划结束时间：">
                  <el-date-picker v-model="formInline.endTime" value-format="yyyy-MM-dd" type="date"
                  :append-to-body="false"
                    placeholder="选择计划结束时间">
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="主讲人，被培训对象姓名：">
                  <el-input clearable v-model="formInline.unite" placeholder="请输入主讲人，被培训对象"
                    class="custom-input"></el-input>
                </el-form-item>
                <el-form-item>
                </el-form-item>
              </el-form>
            </div>
            <div class="newView">
              <el-button type="primary" @click="onSubmit" icon="el-icon-search" class="custom-button">查询</el-button>
              <el-button type="primary" size="small" @click="upDataFile(0)" icon="el-icon-plus"
              :class="isStringInArray('btnAddPlanTrain') ? '' : 'btnShowAuthority'"
                class="custom-button">添加计划</el-button>
              <el-button type="primary" size="small" @click="newType(0)" icon="el-icon-folder-add"
              :class="isStringInArray('btnNewTypeTrain') ? '' : 'btnShowAuthority'"
                class="custom-button">新建类型</el-button>
              <el-button type="primary" :disabled="this.id == ''" size="small" @click="newType(1)"
              :class="isStringInArray('btnModifyTypeTrain') ? '' : 'btnShowAuthority'"
                icon="el-icon-edit-outline" class="custom-button">修改类型</el-button>
              <el-button type="primary" :disabled="this.id == ''" size="small" @click="deleteType(0)"
              :class="isStringInArray('btnDeleteTypeTrain') ? '' : 'btnShowAuthority'"
                icon="el-icon-delete" class="custom-button">删除类型</el-button>
            </div>
          </div>
        </div>
        <div class="table">
          <el-table v-loading="dataLoading" element-loading-text="加载中" element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.3)" :cell-style="{ color: '#AED6FF', fontSize: '12px' }"
            :header-cell-style="{ color: '#e8f4ff', fontSize: '14px' }" border :data="tableData" style="width: 100%"
            class="custom-table">
            <div slot="empty">
              <div v-if="tableData.length == 0">
                <div class="empty-data-view">
                  <div class="tableText">
                    {{ dataLoading ? '' : '暂无数据' }}
                  </div>
                </div>
              </div>
            </div>
            <el-table-column align="center" label="序号" type="index" :index="getIndex" width="100">
            </el-table-column>
            <el-table-column align="center" prop="itemName" label="培训内容" width="300">
            </el-table-column>
            <el-table-column align="center" prop="keynoteSpeaker" label="主讲人" width="120">
            </el-table-column>
            <el-table-column align="center" prop="trainingObjects" label="被培训对象" width="150">
            </el-table-column>

            <el-table-column align="center" prop="startTime" label="计划开始时间" width="180">
            </el-table-column>
            <el-table-column align="center" prop="endTime" label="计划结束时间" width="180">
            </el-table-column>
            <el-table-column align="center" prop="trainingPlace" label="培训地点" width="200">
            </el-table-column>
            <el-table-column align="center" prop="remark" label="备注" :fluid="true" min-width="300">
              <template slot-scope="scope">
                <template v-if="isOverflow(scope.row.remark)">
                  <el-tooltip :content="scope.row.remark" placement="top" :popper-class="'custom-tooltip'">
                    <div class="ellipsis">{{ scope.row.remark }}</div>
                  </el-tooltip>
                </template>
                <template v-else>
                  <div class="ellipsis">{{ scope.row.remark }}</div>
                </template>
              </template>


            </el-table-column>
            <el-table-column align="center" prop="data" fixed="right" label="操作" width="300">
              <template slot-scope="scope">
                <div class="" style="display: flex;justify-content: center; align-items: center;">
                  <div class="" :class="isStringInArray('btnLookFileTrain') ? '' : 'btnShowAuthority'">
                    <el-button @click="lookFile(scope.row, 1)" type="text">查看文件</el-button><span>|</span>
                  </div>
                  <div class="" :class="isStringInArray('btnModifyTrain') ? '' : 'btnShowAuthority'">
                    <el-button @click="replyDialog(scope.row, 1)" type="text">修改</el-button><span>|</span>
                  </div>
                  <div class="" :class="isStringInArray('btnDeleteTrain') ? '' : 'btnShowAuthority'">
                    <el-button @click="deleteTypeList(scope.row, 1)" type="text">删除</el-button><span>|</span>
                  </div>
                  <div class="" :class="isStringInArray('btnUploadFileTrain') ? '' : 'btnShowAuthority'">
                    <el-button @click="openViewDialog(scope.row)" type="text">上传文件</el-button>
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="pagination">
          <div class="btn" @click="startHome">首页</div>
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
            :page-sizes="[10, 20, 30, 40]" :page-size="pageSize" layout="prev, pager, next, sizes"
            :prev-text="prevButtonText" :next-text="nextButtonText" prev-button-class="custom-prev-button"
            next-button-class="custom-next-button" :total="total">
          </el-pagination>
          <div class="btn endBtn" @click="endHome">尾页</div>
          <div class="paginationEnd">
            <div>跳</div>至
            <el-input size="mini" @change="paginationEnd" class="paginationEndIpt" @input="handleEdit"
              v-model.number="paginationEndIpt"></el-input>
            <div>页</div>
            <div class="btn confirm" @click="jumpPage">确定</div>
          </div>
        </div>
      </div>
    </div>

    <!-- 上传文件弹窗 -->
    <el-dialog @close="closeFile" title="上传文件" :visible.sync="centerDialogVisible" width="30%">
      <div class="newCenter">
        <el-form :model="ruleForm1" :rules="rules" ref="ruleForm1" label-width="100px" class="demo-ruleForm">
          <el-form-item label="批量上传" prop="">
            <el-upload action="" ref="upload" :show-file-list="true" :auto-upload="false" :on-remove="handleRemove"
              :on-change="(file, fileList) => {
                imgUpload1(file, fileList);
              }" :limit="10" multiple :file-list="FileList">
              <el-button class="dialogBtn">上传</el-button>
              <div slot="tip" class="el-upload__tip">
                <span style="color: #aed6ff;font-size: 12px;">批量上传一次最多添加9个附件,单个附件最大不超过30M</span>
              </div>
            </el-upload>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">

        <el-button class="dialogBtn" @click="centerDialogVisible = false">取 消</el-button>
        <el-button class="dialogBtn" type="primary" :disabled="file.length == 0" @click="fileUpload">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog :title="searchType == 0 ? '新建类型' : '修改类型'" @close="ruleHandleClose('ruleForm')"
      :visible.sync="replyDialogVisible" width="30%">
      <div class="newCenter">
        <el-form :model="ruleForm" :rules="rulesType" ref="ruleForm" label-width="100px" class="demo-ruleForm">
          <el-form-item label="计划分类：" prop="programName">
            <el-input v-model.trim="ruleForm.programName" show-word-limit maxlength="20"></el-input>
          </el-form-item>
          <el-form-item label="排序：" prop="sort">
            <el-input v-model.trim="ruleForm.sort"></el-input>
          </el-form-item>
          <el-form-item label="备注：" prop="">
            <el-input type="textarea" :rows="4" v-model.trim="ruleForm.remark" show-word-limit maxlength="200"></el-input>
          </el-form-item>
        </el-form>

      </div>
      <span slot="footer" class="dialog-footer">

        <el-button class="dialogBtn" @click="replyDialogVisible = false">取 消</el-button>
        <el-button class="dialogBtn" type="primary" @click="workTypeAdd1('ruleForm')">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 删除分类 -->
    <el-dialog :title="deleteContText == 1 || deleteContText == 0 ? '删除' : '下载'" :visible.sync="centerDialogVisible1"
      width="20%">
      <div class="deleteCenter">
        <!-- 确定删除该分类吗? -->
        {{ deleteContText == 0 ? '确定删除该分类吗?' : deleteContText == 1 ? '确定要删除该数据吗？' : '确定下载该文件?' }}
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="dialogBtn" @click="centerDialogVisible1 = false">取 消</el-button>
        <el-button class="dialogBtn" type="primary" @click="deleteDialog">确 定</el-button>
      </span>


    </el-dialog>
    <!-- 新增弹窗 -->
    <el-dialog @close="tableClose('addForm')" :title="addDialogTitle == 0 ? '新建培训计划' : '修改培训计划'"
      :visible.sync="newTableDialog" width="40%">
      <div class="newCenter">
        <el-form :model="addForm" :rules="addFormRules" ref="addForm" label-width="110px" class="demo-ruleForm">
          <el-form-item label="培训内容：" prop="itemName">
            <el-input class="custom-input" clearable placeholder="请输入培训内容" v-model.trim="addForm.itemName"></el-input>
          </el-form-item>
          <el-form-item label="计划开始时间：" prop="startTime">
            <el-date-picker class="dialogTime" v-model="addForm.startTime" @change="convertToDate"
              :picker-options="pickerOptionsStart" :append-to-body="false" value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime" placeholder="选择计划结束时间">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="计划结束时间：" prop="endTime">
            <el-date-picker class="dialogTime" @change="convertToDate" :picker-options="addPickerOptions"
              :append-to-body="false" v-model="addForm.endTime" value-format="yyyy-MM-dd HH:mm:ss" type="datetime"
              placeholder="选择计划结束时间">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="主讲人" prop="keynoteSpeaker">
            <el-input class="custom-input" clearable placeholder="请输入主讲人" v-model.trim="addForm.keynoteSpeaker"></el-input>
          </el-form-item>
          <el-form-item label="被培训对象：" prop="trainingObjects">
            <el-input class="custom-input" clearable placeholder="请输入被培训对象" v-model.trim="addForm.trainingObjects"></el-input>
          </el-form-item>
          <el-form-item label="培训地点：" prop="trainingPlace">
            <el-input class="custom-input" clearable placeholder="请输入培训地点" v-model.trim="addForm.trainingPlace"></el-input>
          </el-form-item>
          <el-form-item label="备注：" prop="">
            <el-input class="custom-input" show-word-limit maxlength="200" clearable type="textarea" :rows="4"
              v-model.trim="addForm.remark"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="dialogBtn" @click="newTableDialog = false">取 消</el-button>
        <el-button class="dialogBtn" type="primary" @click="newTableList('addForm')">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 查看文件 -->
    <el-dialog title="查看文件" width="50%" :visible.sync="outerVisible">
      <el-table :data="dialogTable" class="custom-table">
        <el-table-column align="center" label="序号" type="index" width="100">
        </el-table-column>
        <el-table-column align="center" property="originalFileName" label="文件名称" ></el-table-column>
        <el-table-column align="center" property="size" label="文件大小" width="150"></el-table-column>
        <el-table-column align="center" property="createTime" label="创建时间" width="200"></el-table-column>

        <el-table-column align="center" property="address" label="操作" width="150">
          <template slot-scope="scope">
            <el-button @click="dialogDownLoad(scope.row)" type="text">下载</el-button><span>|</span>
            <el-button @click="dialogDeleteTab(scope.row)" type="text">删除</el-button>
          </template>
        </el-table-column>

      </el-table>
      <div class="pagination">
          <div class="btn" @click="startHomeDialog">首页</div>
          <el-pagination @size-change="handleSizeChangeDialog" @current-change="handleCurrentChangeDialog" :current-page="currentPageDialog"
            :page-sizes="[10, 20, 30, 40]" :page-size="pageSizeDialog" layout="prev, pager, next, sizes"
            :prev-text="prevButtonText" :next-text="nextButtonText" prev-button-class="custom-prev-button"
            next-button-class="custom-next-button" :total="totalDialog">
          </el-pagination>
          <div class="btn endBtn" @click="endHomeDialog">尾页</div>
          <div class="paginationEnd">
            <div>跳</div>至
            <el-input size="mini" @change="paginationEndDialog" class="paginationEndIpt" @input="handleEditDialog"
              v-model.number="paginationIptDialog"></el-input>
            <div>页</div>
            <div class="btn confirm" @click="jumpPageDialog">确定</div>
          </div>
        </div>

      <el-dialog width="20%" title="下载" :visible.sync="innerVisible" append-to-body>
        <div class="deleteCenter">
          确定要下载该文件吗？
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button class="dialogBtn" @click="innerVisible = false">取 消</el-button>
          <el-button :loading="btnLoading" class="dialogBtn" type="primary" @click="innerVisibleDownLoad">确 定</el-button>
        </span>
      </el-dialog>
      <el-dialog width="20%" title="删除" :visible.sync="dialogDelete" append-to-body>
        <div class="deleteCenter">
          确定要删除该文件吗？
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button class="dialogBtn" @click="dialogDelete = false">取 消</el-button>
          <el-button class="dialogBtn" type="primary" @click="dialogDeleteFile">确 定</el-button>
        </span>
      </el-dialog>
    </el-dialog>

  </div>
</template>

<script>
import { trainDialogFileDelete, trainListUpdate, trainListAdd, trainTree, trainTreeAdd, trainTreeUpdate, trainTreeDelete, trainListQuery, trainListDelete, updateFileTrain, queryFileList, downLoadFileTrain } from '../../api/planAdministration.js'
export default {
  name: 'Train',
  data() {
    return {
      btnLoading: false,
      content: '',
      FileList: [],
      formInline: {
        unite: '',
        startTime: null,
        endTime: null,
        trainingProgramId: ''
      },
      tableData: [

      ],
      centerDialogVisible: false,
      replyDialogVisible: false,
      centerDialogVisible1: false,
      newTableDialog: false,
      outerVisible: false,
      dialogDelete: false,
      innerVisible: false,
      deleteContText: '',
      prevButtonText: '上一页',
      nextButtonText: '下一页',
      paginationEndIpt: '',
      pageSize: 10,
      currentPage: 1,
      total: 0,
      pageSizeDialog: 10,
      currentPageDialog: 1,
      totalDialog: 40,
      paginationIptDialog:1,
      textarea: '',
      dataList: [
        {
          label: '全部',
          children: [

          ]
        },

      ],
      dialogTable: [
      ],
      treeTitle: [],
      treeText: '',
      selectContent: '',
      icon: true,
      ruleForm: {
        programName: '',
        remark: '',
        sort: null,
      },
      ruleForm1: {
        dictName: '',
        dictCode: '',
        dictDesc: ''
      },
      id: '',
      originalFileName: '',
      searchType: 0,
      tableId: '',
      tableDialogId: '',
      file: [],
      fileName: [],
      dataLoading: false,
      updateId: '',
      addForm: {
        itemName: '',
        startTime: null,
        endTime: null,
        keynoteSpeaker: '',
        trainingObjects: '',
        trainingPlace: '',
        remark: '',
      },
      addDialogTitle: 0,
      rules: {
        dictName: [
          { required: true, message: '请输入文件类型', trigger: 'blur' },
        ],
        dictCode: [
          { required: true, message: '请选择文件', trigger: 'blur' },
        ],
      },
      rulesType: {
        programName: [
          { required: true, message: '请输入计划分类', trigger: 'blur' },
        ],
        sort: [
          { required: true, message: '请输入排序', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (/^(?=.*[0-9])\d*$/.test(value)) {
                callback();
              } else {
                callback(new Error("请输入包含0的正整数"));
              }
            },
            trigger: "blur"
          }

        ],
      },
      addFormRules: {
        itemName: [
          { required: true, message: '请输入培训内容', trigger: 'blur' },
        ],
        startTime: [
          { required: true, message: '请选择计划开始时间', trigger: 'blur' },
        ],
        endTime: [
          { required: true, message: '请选择计划结束时间', trigger: 'blur' },
        ],
        keynoteSpeaker: [
          { required: true, message: '请输入主讲人', trigger: 'blur' },
        ],
        trainingObjects: [
          { required: true, message: '请输入被培训对象', trigger: 'blur' },
        ],
        trainingPlace: [
          { required: true, message: '请输入培训地点', trigger: 'blur' },
        ],

      },
      pickerOptionsStart: {
        disabledDate(time) {

          return time.getTime() < Date.now() - 86400000;
        }
      },
      authorityListBtn:[]

    };
  },
  // components:{demo},

  mounted() {
    // let pageText = document.getElementsByClassName('el-pagination__jump')[0]
    // pageText.childNodes[0].nodeValue = '跳至'
    // pageText.childNodes[2].nodeValue = '页'
    this.getTreeList()
    this.authorityListBtn=sessionStorage.getItem('roleInfo')

  },
  created() {
    this.$store.commit('increment', '计划管理')
    this.$store.commit('selectChild', '培训计划')
    this.$store.commit('selectChildren', '')
    // this.getList()
  },
  computed: {


    //  pickerOptions() {
    //    const self = this;
    //    return {
    //      disabledDate(time) {
    //        const limitDate = new Date(self.formInline.startTime);
    //        limitDate.setHours(0, 0, 0, 0); // 重置时间为当天的开始时间

    //        return time.getTime() < limitDate.getTime();
    //      }
    //    };
    //  },
    addPickerOptions() {
      const self = this;
      return {
        disabledDate(time) {
          const limitDate = new Date(self.addForm.startTime);
          limitDate.setHours(0, 0, 0, 0); // 重置时间为当天的开始时间

          return time.getTime() < limitDate.getTime();
        }
      };
    }
  },
  watch: {
    // 如果只需要监听对象中的一个属性值，则可以做以下优化：使用字符串的形式监听对象属性：
    "currentPage": {
      // 执行方法
      handler(newValue, oldValue) {
        this.paginationEndIpt = newValue;
      },
      immediate: true, // 第一次改变就执行
    },
  },

  methods: {
     //按钮权限
     isStringInArray(str) {
      if (this.authorityListBtn) {
        let list = this.authorityListBtn.includes(str);
        return list
      } else {
        return false
      }
    },
    //时间校验
    convertToDate() {
      console.log("");
      if (this.addForm.startTime && this.addForm.endTime) {

        const startTime = new Date(this.addForm.startTime).getTime();
        const endTime = new Date(this.addForm.endTime).getTime();
        // const duration = (endTime - startTime) / (1000 * 60 * 60); // 时间差转换成小时
        // this.ruleForm.duration = Number(duration.toFixed(1)); // 保留一位小数
        console.log(startTime, endTime);
        if (startTime == endTime) {
          this.$message({
            message: '计划开始时间与计划结束时间不能相同',
            type: 'warning',
            duration: 3000,
            customClass: "messageText",
          });
          this.addForm.endTime = null
        }else if(startTime > endTime){
          this.$message({
            message: '计划开始时间不能大于计划结束时间',
            type: 'warning',
            duration: 3000,
            customClass: "messageText",
          });
          this.addForm.endTime = null
        }
      }
    },

    isOverflow(content) {
      const lineHeight = 16;
      const numLines = Math.ceil(content.length / 42); // 每行大概显示42个字符
      // console.log("====>",numLines);
      return numLines >= 3;
    },

    tableClose(addForm) {
      this.addForm = this.$options.data().addForm
      this.$refs.addForm.clearValidate()
    },
    //新增table
    newTableList(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          let obj = {
            ...this.addForm,
            trainingProgramId: this.id
          }
          let func = this.addDialogTitle == 0 ? trainListAdd(obj) : trainListUpdate(this.addForm)

          func.then(res => {
            if (res.code == 200) {
              this.newTableDialog = false
              this.getTreeList()
              this.$message({
                message: res.message,
                type: 'success',
                duration: 3000,
                customClass: "messageText",
              });
            } else {
              this.$message({
                message: res.message,
                type: 'error',
                duration: 3000,
                customClass: "messageText",
              });

            }
          })

        } else {
          console.log('error submit!!');
          return false;
        }
      });

    },


    closeFile() {
      this.file = []
      this.FileList = []
    },
    //序号
    getIndex(index) {
      return (this.currentPage - 1) * this.pageSize + index + 1;
    },
    //关闭弹窗
    handleClose() {
      this.addForm = this.$options.data().addForm
    },
    ruleHandleClose(ruleForm) {
      this.ruleForm = this.$options.data().ruleForm
      this.$refs[ruleForm].resetFields();
    },
    fileUpload() {
      let file = this.file
      let formData = new FormData();
      console.log(file);
      for (let i = 0; i < file.length; i++) {
        formData.append("multipartFiles", file[i]);
      }
      // formData.append("multipartFiles", file[0]);
      formData.append("id", this.updateId);
      updateFileTrain(formData).then(res => {
        if (res.code == 200) {
          this.ruleForm1.dictCode = file.name
          this.centerDialogVisible = false
          this.file = []
          this.FileList = []
          this.getTreeList()
          this.$message({
            message: res.message,
            type: 'success',
            duration: 3000,
            customClass: "messageText",
          });
        } else {
          this.$message({
            message: res.message,
            type: 'error',
            duration: 3000,
            customClass: "messageText",
          });
        }
      })
    },

    //上传
    imgUpload1(file, fileList) {
      // console.log(fileList.length);
      if (file.size < 31457280) {
        if (fileList.length <= 9) {
          this.file = fileList.map(item => item.raw);
        } else {
          fileList.pop()
          this.$message({
            message: '您已选择9个附件',
            type: 'warning',
            duration: 3000,
            customClass: "messageText",
          });
        }
      } else {
        fileList.pop()
        this.$message({
          message: `您选择的文件:${file.name}大于30M`,
          type: 'warning',
          duration: 3000,
          customClass: "messageText",
        });

      }
    },
    handleRemove(file,fileList) {
      console.log(file);
      // console.log(this.$refs.upload.fileList.length);
      this.file = fileList.map(item => item.raw);
      // this.file = fileList.map(item => item.raw);
      // this.file.push(file.row)
    },
    //查看文件
    lookFile(el, type) {
      this.outerVisible = true
      if (el.id) {
        this.tableId = el.id
      }
      this.getListDialog()
    },
    getListDialog(){
      let obj ={
        id: this.tableId,
        pageNum:this.currentPageDialog,
        pageSize:this.pageSizeDialog
      }
      queryFileList(obj).then(res => {
        if (res.code == 200) {
          this.dialogTable = res.data
          this.totalDialog=res.total
        } else {
          this.$message({
            message: res.message,
            type: 'error',
            duration: 3000,
            customClass: "messageText",
          });
        }
      })

    },
    //dialog删除
    dialogDeleteTab(el) {
      this.dialogDelete = true
      this.tableDialogId = el.id
    },
    //删除文件
    dialogDeleteFile() {
      console.log(this.tableDialogId);
      trainDialogFileDelete(this.tableDialogId).then(res => {
        if (res.code == 200) {
          this.$message({
            message: res.message,
            type: 'success',
            duration: 3000,
            customClass: "messageText",
          });
          this.dialogDelete = false
          this.lookFile('', 11)
        } else {
          this.$message({
            message: res.message,
            type: 'error',
            duration: 3000,
            customClass: "messageText",
          });

        }
      })

    },
    dialogDownLoad(el) {
      this.innerVisible = true
      this.tableDialogId = el.id
      this.originalFileName = el.originalFileName
    },
    //下载确定
    innerVisibleDownLoad() {
      this.btnLoading = true
      downLoadFileTrain({ id: this.tableDialogId }).then(res => {
        console.log("res=====>", res);
        const link = document.createElement('a');
        // const filename = res.headers['Content-Disposition'].split('filename=')[1]; // 获取文件名称
        link.href = URL.createObjectURL(new Blob([res]));
        link.download = decodeURIComponent(this.originalFileName); // 解码文件名称，避免名称中含有中文时乱码
        link.click();
        this.innerVisible = false
        this.btnLoading = false
      })

    },

    //删除确定
    deleteDialog() {
      //删除分类
      if (this.deleteContText == 0) {
        trainTreeDelete(this.id).then(res => {
          if (res.code == 200) {
            this.centerDialogVisible1 = false
            this.$message({
              message: res.message,
              type: 'success',
              duration: 3000,
              customClass: "messageText",
            });
            this.id = ''
            this.tableId=''
            this.getTreeList()
          } else {
            this.$message({
              message: res.message,
              type: 'error',
              duration: 3000,
              customClass: "messageText",
            });
          }
        })
      }
      //删除table
      if (this.deleteContText == 1) {
        trainListDelete(this.tableId).then(res => {
          if (res.code == 200) {
            this.centerDialogVisible1 = false
            this.$message({
              message: res.message,
              type: 'success',
              duration: 3000,
              customClass: "messageText",
            });
            this.getList()

          } else {
            this.$message({
              message: res.message,
              type: 'error',
              duration: 3000,
              customClass: "messageText",
            });
          }
        })

      }
      //下载文件
      if (this.deleteContText == 2) {


      }

    },

    //新增类型
    workTypeAdd1(ruleForm) {
      // if (this.searchType == 1 ) {

      //   console.log("123456");
      //   let content = this.dataList[0].children[0]
      //   this.ruleForm.name = content.name
      //   this.ruleForm.remark = content.remark
      // }
      this.$refs[ruleForm].validate((valid) => {
        if (valid) {
          let func = this.searchType == 0 ? trainTreeAdd(this.ruleForm) : trainTreeUpdate({ ...this.ruleForm, id: this.id })
          func.then(res => {
            if (res.code == 200) {
              this.replyDialogVisible = false
              this.$message({
                message: res.message,
                type: 'success',
                duration: 3000,
                customClass: "messageText",
              });
              this.$refs.ruleForm.resetFields();
              this.ruleForm = this.$options.data().ruleForm
              this.getTreeList()

            } else {
              this.$message({
                message: res.message,
                type: 'error',
                duration: 3000,
                customClass: "messageText",
              });
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });

    },
    //左侧tree
    getTreeList() {
      trainTree().then(res => {
        // console.log(res);
        if (res.code == 200) {
          this.dataList[0].children = res.data
          if(res.data.length > 0){
            if (this.id == '') {
              this.formInline.trainingProgramId = this.dataList[0].children[0].id
              this.selectContent = this.dataList[0].children[0].id
              this.content = this.dataList[0].children[0]
              this.id = this.dataList[0].children[0].id
              this.tableId = this.dataList[0].children[0].id
            } else {
              let information = this.dataList[0].children.find(item => item.id === this.id);
              console.log(information);
              this.formInline.trainingProgramId = information.id
              this.selectContent = information.id
              this.content = information
            }
          }
          // console.log("11111111",this.id);
          this.getList()

        } else {
          this.$message({
            message: res.message,
            type: 'error',
            duration: 3000,
            customClass: "messageText",
          });
        }
      })

    },
    // 
    searchIcon() {
      this.icon = !this.icon
    },
    //列表查询
    getList() {
      this.dataLoading = true
      let obj = {
        ...this.formInline,
        pageNum: this.currentPage,
        pageSize: this.pageSize
      }
      trainListQuery(obj).then(res => {
        this.dataLoading = false
        // console.log(res);
        if (res.code == 200) {
          this.tableData = res.data
          this.total = res.total
        } else {
          this.$message({
            message: res.message,
            type: 'error',
            duration: 3000,
            customClass: "messageText",
          });
        }
      })
    },
    //修改
    replyDialog(el, type) {
      console.log(el.data);
      this.tableId = el.id
      this.originalFileName = el.originalFileName
      this.newTableDialog = true
      this.deleteContText = type
      this.addForm = Object.assign({}, el);
      this.addDialogTitle = type
    },
    //查询列表
    onSubmit() {
      console.log('submit!');
      this.getList()

    },

    //shangchaunwenjian tanchuang 
    openViewDialog(el) {
      this.updateId = el.id
      this.centerDialogVisible = true
    },
    // //上传文件
    upDataFile(type) {
      if(this.tableId){

        this.addDialogTitle = type
        this.newTableDialog = true
      }else{
        this.$message({
            message: '请创建分类后创建计划',
            type: 'warning',
            duration: 3000,
            customClass: "messageText",
          });
      }
      // this.centerDialogVisible = true
    },
    //新建类型
    newType(type) {

      this.searchType = type
      this.replyDialogVisible = true
      if (type == 1) {
        let content = this.content
        this.id = content.id
        this.ruleForm.programName = content.programName
        this.ruleForm.sort = content.sort
        this.ruleForm.remark = content.remark
        console.log("222222");
      } else {
        console.log("111111111111");
        this.ruleForm = this.$options.data().ruleForm
      }
    },

    //删除
    deleteType(type) {
      this.centerDialogVisible1 = true
      this.deleteContText = type
    },
    deleteTypeList(el, type) {
      this.tableId = el.id
      this.originalFileName = el.originalFileName
      this.centerDialogVisible1 = true
      this.deleteContText = type
    },



    //分页相关function
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val
      this.paginationEnd()
      this.getList()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.paginationEndIpt = val
      console.log(`当前页: ${val}`);
      console.log(this.currentPage);
      this.getList()
    },
    //首页
    startHome() {
      this.currentPage = 1
      this.getList()

    },
    //尾页
    endHome() {
      const lastPage = Math.ceil(this.total / this.pageSize);
      this.currentPage = lastPage;
      this.getList()
    },
    //校验输入
    handleEdit(e) {
      let value = e.replace(/[^\d]/g, ""); // 只能输入数字
      value = value.replace(/^0+(\d)/, "$1"); // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
      this.paginationEndIpt = value
      // console.log('value===>',value);
    },
    //页数输入时
    paginationEnd() {
      const lastPage = Math.ceil(this.total / this.pageSize);
      if (this.paginationEndIpt < 1) {
        this.paginationEndIpt = 1
      } else {
        this.paginationEndIpt = this.paginationEndIpt < lastPage ? this.paginationEndIpt : lastPage
      }
    },
    //跳转
    jumpPage() {
      this.currentPage = this.paginationEndIpt
      this.getList()
      // this.paginationEndIpt=''
    },
    //dialogtable 分页
    handleSizeChangeDialog(val) {
      console.log(`每页 ${val} 条`);
      this.pageSizeDialog = val
      this.paginationEndDialog()
      this.getListDialog()
    },
    handleCurrentChangeDialog(val) {
      this.currentPageDialog = val
      this.paginationIptDialog = val
      console.log(`当前页: ${val}`);
      console.log(this.currentPage);
      this.getListDialog()
    },
    //首页
    startHomeDialog() {
      this.currentPage = 1
      this.getListDialog()

    },
    //尾页
    endHomeDialog() {
      const lastPage = Math.ceil(this.total / this.pageSize);
      this.currentPageDialog = lastPage;
      this.getListDialog()
    },
    //校验输入
    handleEditDialog(e) {
      let value = e.replace(/[^\d]/g, ""); // 只能输入数字
      value = value.replace(/^0+(\d)/, "$1"); // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
      this.paginationIptDialog = value
      // console.log('value===>',value);
    },
    //页数输入时
    paginationEndDialog() {
      const lastPage = Math.ceil(this.totalDialog / this.pageSizeDialog);
      // this.paginationIptDialog=parseInt(this.paginationIptDialog)
      console.log("1111111111",typeof(this.paginationIptDialog));
      if (this.paginationIptDialog < 1) {
        this.currentPageDialog = 1
      } else {
        this.currentPageDialog = this.paginationIptDialog < lastPage ? this.paginationIptDialog : lastPage
      }
    },
    //跳转
    jumpPageDialog() {
      this.currentPageDialog = parseInt(this.paginationIptDialog)
      this.getListDialog()
      // this.paginationEndIpt=''
    },
    //点击父节点
    titleTree(item) {
      // this.treeTitle.push(item.label)
      // this.treeTitle = [...new Set(this.treeTitle)];
      console.log(this.treeTitle);
      // const index = this.treeTitle.findIndex(item => item.label === item.label);
      const index = this.treeTitle.indexOf(item.label);
      if (index !== -1) {
        // 对象已存在于数组中，删除它
        this.treeTitle.splice(index, 1);
      } else {
        // 对象不存在于数组中，添加它
        this.treeTitle.push(item.label);
      }

    },
    // 点击子节点
    treeContent(item, content) {
      this.content = content
      this.searchType = 1
      console.log(item, content);
      this.ruleForm.name = content.name
      this.ruleForm.remark = content.remark
      this.id = content.id
      this.treeText = item.id

      this.selectContent = content.id
      this.formInline.trainingProgramId = content.id
      console.log(this.ruleForm);
      this.getList()
    }


  },
};
</script >
<style src="@/utils/style/table.css" scoped></style>
<style scoped>
/* import */
.content {
  width: 100%;
  height: 100%;
  min-height: 781px;
  background: url(../../assets/images/rightBG.png);
  background-repeat: no-repeat;
  background-position: left left;
  background-size: cover;
  font-size: 16px;
  display: flex;

}

.contentL {
  width: 400px;
  margin: 28px 0 30px 0px;
  /* background: #071536; */

}

.contentR {
  width: 100%;
  overflow: auto;
}

.prompt {
  padding: 28px 0 0 17px;
  font-size: 16px;
  color: #ebf4ff;
  display: flex;
  align-items: center;
}

.promptText {
  margin-left: 10px;
}

/* .el-input>>>.el-input__inner {
  background-color: #061b45;
  border: 1px solid #09295b;
  color: #AED6FF;
}

.el-form-item>>>.el-form-item__label {
  font-size: 12px;
  color: #AED6FF;
}

.custom-input ::placeholder {
  color: #AED6FF;
}

.custom-button {
  background: #176ad2;
} */

.searchContent {
  margin: 11px 25px 16px 26px;
}

.search {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 14px 26px 23px 20px;

  background: #061C45;
}

.custom-pager-text {
  margin-right: 5px;
}

.custom-page-input {
  width: 60px;
  margin-right: 5px;
}

.custom-page-button {
  padding: 5px 10px;
}


.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 21px;
}

.btn {
  width: 60px;
  height: 26px;
  line-height: 26px;
  text-align: center;
  background: rgba(255, 255, 255, 0);
  border: 1px solid #0b2653;
  border-radius: 2px;
  color: #92b5da;

}

.btn:hover {
  color: #ffffff;
}

.el-pagination>>>.btn-prev,
.el-pagination>>>.btn-next {
  background: rgba(255, 255, 255, 0);
  color: #d7d7d7;
  border-radius: 2px;
  border: 1px solid #0b2653;
  margin-right: 5px;
}

.el-pagination>>>.el-pager li {
  background: rgba(255, 255, 255, 0);
  color: #d7d7d7;
  border-radius: 2px;
  border: 1px solid #0b2653;
  margin-right: 5px;
}

.el-pagination>>>.el-pager .active {
  background: #176AD2;
  color: #d7d7d7;
  border-radius: 2px;
  border: 1px solid #0b2653;
  margin-right: 5px;
}

::v-deep .el-input__inner {
  background: rgba(255, 255, 255, 0);
  color: #d7d7d7;
  border-radius: 2px;
  border: 1px solid #0b2653;
  height: 40px !important;

}

.endBtn {
  margin-left: -15px;
  margin-right: 10px;
}

.paginationEnd {
  display: flex;
  justify-content: center;
  align-items: center;
}

.paginationEndIpt {
  width: 30px;
  margin: 0 7px;
  padding: 0;
  color: #92b5da;
  /* height: 16px; */
}

.paginationEndIpt>>>.el-input__inner {
  padding: 0 5px;
  height: 40px !important;
}

.confirm {
  margin-left: 10px;
  background: #176AD2;
  color: #d7d7d7;
}

/* 弹窗 */
::v-deep .el-dialog {
  background-color: #061d4a;
  font-size: 16px;
}

::v-deep .el-dialog__header {
  border-bottom: 1px solid #09295b;
}

::v-deep .el-dialog__title {
  font-size: 16px;
  color: #fff;
}

.dialog-footer {
  display: flex;
  justify-content: center;
  font-size: 16px;
  color: #000;
}

.dialogBtn {
  width: 100px;
  height: 35px;
  background: #2142A1;
  border: 0;
  color: #fff;
}

.newCenter {
  margin-bottom: 20px;
  /* display: flex; */
}

.newCenterText {
  width: 80px;
  color: #C9D4D4;
}

::v-deep .el-textarea__inner {
  background-color: #061D4A;
  color: #AED6FF;
  border: 1px solid #09295b;
  font-family: "Microsoft YaHei", Arial, sans-serif;   
}

.custom-input-textarea ::placeholder {
  color: #667597;
}

.newCenterKey {
  width: 50px;
  color: #C9D4D4;
}


.tree {
  margin-top: 21px;
  /* background: #071734 ; */
  color: #aed6ff;

}

.treeContent {
  height: 40px;
  width: 250px;
  /* line-height: 59px; */
  font-size: 14px;
  margin-left: 52px;
  display: flex;
  align-items: center;

}

.treeTitle {
  height: 32px;
  line-height: 32px;
  margin-left: 22px;
  display: flex;
  align-items: center;
  font-size: 14px;
}

.treeTitle img {
  margin-right: 10px;
}

.searchIcon {
  width: 25px;
  height: 15px;
  background: url(../../assets/images/dailyWork/BG.png);
  background-repeat: no-repeat;
  background-position: -5px -15px
}

.search-title {
  /* background-position: -5px -15px; */
  background-position: -27px -15px;
}

.searchContent1 {
  width: 8px;
  padding: 0px 0 0 0;
  margin: 0px 0 0 0px;
  height: 100%;
  /* border: 1px solid red; */
  background: url(../../assets/images/dailyWork/line.png);
  /* background-size: cover; */
  background-position: 0px 39px;

}

.treeContentText {
  margin-top: 40px;
  padding: 2px;
  display: flex;
  align-items: center;
  cursor: pointer;
  /* background: #000; */

}

.treeContentText img {
  height: 100%;

}

.treeContentText div {
  height: 20px;
}

.treeContentText :hover {
  /* background: #0E2751; */
  color: #FFC74A;
  /* background: #1b386b; */

}

.treeContentText1 {
  /* background: #1b386b; */

  color: #FFC74A;

}

.demo {
  /* width: 125px; */

}

::v-deep .el-input-group__append {
  background: #2142A1;
  color: #EFFDEE;
  border: 0;
}

.deleteCenter {
  font-size: 16px;
  color: #EBEDF1;
}

.fileList {
  color: #ead6ff;
  font-size: 12px;
  line-height: 18px;
}

::v-deep .el-upload-list__item:hover {
  background: #061D4A;
}

::v-deep .el-upload-list__item-name {

  color: #aed6ff;
}

.dialogTime {
  width: 100%;
}

.custom-input {
  width: 100%;
}

/*时间日期选择器默认样式修改 */
::v-deep .el-time-panel {
  background: #041C4C;
  border: 0;
  color: #aed6ff;
}

::v-deep .el-time-panel__footer {
  border-color: #aed6ff;
}

::v-deep .el-time-spinner__item {
  color: #aed6ff;
}

::v-deep .el-time-spinner__item.active:not(.disabled) {
  color: #aed6ff;
}

::v-deep .el-time-panel__btn {
  color: #aed6ff;
}

::v-deep .el-time-panel__btn.confirm {
  color: #409EFF;
}

::v-deep .el-picker-panel__footer {
  color: #aed6ff;
  background: #041C4C;
}
::v-deep .el-picker-panel__footer .el-button--text{
  display: none;
}
::v-deep .is-plain {
  background: #176AD2;
  border: 0;
  color: #fff;
}

::v-deep .el-time-spinner__item:hover:not(.disabled):not(.active) {
  background: #041C4C;
}


/* 修改 hover 时小 x 的颜色 */
::v-deep .el-icon-close:before {
  color: #aed6ff;
}
</style>
<style>
.custom-tooltip {
  max-width: 600px;
  /* 设置最大宽度 */
  line-height: 20px;
}</style>